<template>
  <div
    class="setup"
    v-show="
      session.config && session.config['app.is_demo'] == 1 && step && step < 5
    "
  >
    <div class="setup-modal" :style="step == -1 ? 'width: 750px' : ''">
      <div
        class="content"
        v-if="!loading"
        :style="step == -1 ? 'width: 700px' : ''"
      >
        <template v-if="step == 1">
          <img src="/img/icons/icons8-domain-96.png" />
          <h1>Alan Adını Bağla</h1>
          <div class="small-text">
            Websitenizin canlı ortama geçiş yapması için alan adınızı giriniz.
            Ardından
            <span class="link link--black">Name Server</span>
            yönlendirme işlemi gerçekleştireceksiniz.
          </div>
          <div class="m-content">
            <fieldset class="input-group">
              <input
                type="url"
                class="input i-medium"
                placeholder="https://"
                v-model="domain"
              />
            </fieldset>
            <div class="card alert-danger mb-3" v-if="errorMessage">
              <span class="error-text">
                <i class="fas fa-exclamation-circle"></i>
                {{ errorMessage }}</span
              >
            </div>
            <button
              class="btn btn--medium btn--primary"
              :disabled="submit"
              @click="createCloudflareZone()"
            >
              {{ submit ? "Alan Adı Kaydınız Oluşturuluyor..." : "Oluştur" }}
              <i class="fas fa-spinner fa-spin" v-if="submit"></i>
            </button>
          </div>
        </template>
        <template v-else-if="step == 2">
          <img src="/img/icons/icons8-server-100.png" />
          <h1>Alan Adını Yönlendir</h1>
          <div class="small-text">
            Domain sağlayıcınıza giriş yaparak
            <span class="link link--black">Alan Adınızı</span> aşağıda bulunan
            Name Server sunucularımıza yönlendiriniz. Bu işlemden sonra aşağıda
            bulunan
            <b> Alan Adını Yönlendirdim <i class="fas fa-thumbs-up"></i></b>
            butonuna tıklayınız.
          </div>
          <div class="m-content" style="width: 100%">
            <div class="row">
              <h5>
                <i class="fa fa-trash" style="color: #e72626"></i> Mevcut Ad
                Sunucuları (NS)
              </h5>
              <div class="col-12 name-server">
                <p style="margin: 0">
                  {{ getOriginalNameServer(0) }} <br />
                  {{ getOriginalNameServer(1) }}
                </p>
              </div>
            </div>
            <div class="row mt-3">
              <h5>
                <i class="fas fa-check-circle" style="color: #28a745"></i>
                Yönlendirilecek Ad Sunucuları (NS)
              </h5>
              <div class="col-12 name-server">
                <p style="margin: 0">
                  {{ getNameServer(0) }}
                  <i class="fas fa-copy" @click="copyNameServer(0)"></i> <br />
                  {{ getNameServer(1) }}
                  <i class="fas fa-copy" @click="copyNameServer(1)"></i>
                </p>
              </div>
              <div
                class="small-text"
                style="width: 100%; margin: 10px 0px; cursor: pointer"
                @click="nextStep(-1)"
              >
                Mevcut
                <span class="link link--black"
                  ><i class="fas fa-list"></i> DNS
                </span>
                kayıtlarınızın listesini görmek görmek için tıklayınız.
              </div>
              <button
                class="btn btn--medium btn--primary"
                :disabled="submit"
                @click="completed()"
              >
                Alan Adını Yönlendirdim
                <i
                  :class="
                    submit ? 'fas fa-spinner fa-spin' : 'fas fa-thumbs-up'
                  "
                ></i>
              </button>
            </div>
          </div>
        </template>
        <template v-else-if="step == 3">
          <img src="/img/icons/icons8-clock-100.png" />
          <h1>Teşekkürler</h1>
          <div class="small-text">
            Geriye kalan tüm işlemler
            <span class="link link--black">UNIQYL</span> servisleri tarafından
            otomatik olarak gerçekleşecektir. Websiteniz Canlı ortama geçiş
            yaptığında sms ve mail ile bilgilendirileceksiniz. Anlayışınız ve
            sabırınız için teşekkür ederiz.
          </div>
        </template>
        <template v-else-if="step == -1">
          <div class="small-text">
            Mevcut DNS kayıtlarınız aşağıdaki gibidir. Eğer bu dns kayıtlarına
            ek olarak farklı DNS kayıtları eklemek isterseniz
            <a href="mailto:support@uniqyl.com" class="link link--black"
              >support@uniqyl.com</a
            >
            adresinden teknik ekibimiz ile iletişime geçebilirsiniz.
          </div>
          <div class="m-content" style="width: 100%">
            <div class="row mt-3">
              <div class="col-12">
                <WeTable
                  v-bind:index="false"
                  v-bind:data.sync="dns_records"
                  v-bind:columns="columns"
                  :searchable="false"
                  :limitable="false"
                  :itemsPerPage="8"
                ></WeTable>
                <button
                  class="back-btn btn btn--medium btn--primary"
                  @click="backStep(2)"
                >
                  <i class="fas fa-chevron-left"></i> Geri Dön
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <img src="/img/icons/icons8-web-settings-100.png" />
          <h1>Kurulum İşlemleri Devam Ediyor...</h1>
          <div class="small-text">
            Gerekli tüm işlemleri gerçekleştiriyoruz. Her şey hazır olduğunda
            SMS ve Mail ile bilgilendirileceksiniz.
          </div>
        </template>
      </div>
      <div v-else>
        <h4>Veriler yükleniyor...</h4>
        <p class="text-center"><i class="fas fa-spinner fa-spin"></i></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      submit: false,
      diffDays: null,
      loading: true,
      domain: null,
      errorMessage: null,
      step: null,
      name_servers: [],
      original_name_servers: [],
      dns_records: [],
      columns: [
        { name: "type", th: "Type", type: "string" },
        { name: "name", th: "Name", type: "string" },
        { name: "content", th: "Content", type: "string" },
      ],
    };
  },
  methods: {
    ...mapActions("session", ["getConfig", "updateConfig"]),
    ...mapActions("live", ["createZone", "completedNameServer"]),
    getExpireDay() {
      const nowDate = new Date(Date.now());
      const expireDate = new Date(this.session.config["app.expired_at"]);
      const diffTime = expireDate - nowDate;

      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      //if (!this.step && diffDays <= 0) this.step = 1;
      this.diffDays = diffDays;
    },
    IsURL(str) {
      const pattern = new RegExp(
        "^([a-zA-Z]+:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$", // fragment locator
        "i"
      );
      return pattern.test(str);
    },
    writeData(result) {
      this.name_servers = result?.data?.name_servers;
      this.original_name_servers = result?.data?.original_name_servers;
      this.dns_records = result?.data?.dns_records?.result ?? [];
    },

    completed() {
      this.submit = true;
      this.completedNameServer({
        onSuccess: (result) => {
          if (result?.data?.status) {
            this.step = 3;
          } else {
            this.$swal({
              title: "Hata",
              html:
                result?.data?.error ??
                "Bilinmeyen bir hata oluştu lütfen ekibimiz ile iletişime geçiniz.>",
              icon: "error",
            });
          }
          this.submit = true;
        },
      });
    },
    createCloudflareZone() {
      this.errorMessage = null;

      if (!this.IsURL(this.domain)) {
        this.errorMessage = "Lütfen geçerli bir Alan Adı giriniz!";
        return;
      }
      this.submit = true;
      this.createZone({
        domain: this.domain,
        onSuccess: (result) => {
          if (result?.data?.status) {
            this.writeData(result?.data);
            this.step = 2;
          } else {
            this.errorMessage =
              result?.data?.error ??
              "Bir hata oluştu lütfen daha sonra tekrar deneyiniz.";
          }
          this.submit = false;
        },
      });
    },
    copyNameServer(i) {
      const txt = this.getNameServer(i);
      navigator.clipboard.writeText(txt);
      this.$toast.info(`${txt} kopyalandı.`);
    },
    backStep(step) {
      this.step = step;
    },
    nextStep(step) {
      this.step = step;
    },
    getNameServer(index = 0) {
      return this.name_servers ? this.name_servers[index] : null;
    },
    getOriginalNameServer(index = 0) {
      return this.original_name_servers.length
        ? this.original_name_servers[index]
        : null;
    },
  },
  computed: {
    ...mapState(["session"]),
    ...mapState({
      config: (state) => state.session.config,
    }),
  },
  mounted() {
    if (this.session.userId) {
      this.loading = true;
      this.getConfig({
        onSuccess: (result) => {
          this.getExpireDay();
          if ((this.config["site.live_step"] ?? 1) == 2) {
            try {
              let data = JSON.parse(this.config["site.live_step_2"]);
              this.writeData(data);
            } catch (ex) {
              console.log(ex);
              this.$swal({
                title: "Hata",
                html: "Name server verileri alınırken bir hata oluştu. Lütfen teknik ekibimiz ile iletişime geçiniz.",
                icon: "error",
              });
            }
          }
          this.step = this.config["site.live_step"] ?? this.step;
          this.loading = false;
        },
      });
    }
  },
  created() {
    if (this.session.userId) {
      this.getExpireDay();
    }
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  height: 30px !important;
  width: 100px !important;
  float: right !important;
  font-size: 14px !important;
  padding: 0 !important;
  margin: 10px 0px !important;
  line-height: 0px !important;
}

.live-modal-title {
  font-size: 30px;
  font-weight: bold;
  color: #d1550e;
  text-align: center;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;900&display=swap");
body {
  min-height: 100vh;
  display: flex;
  font-family: Inter;
  align-items: center;
  justify-content: center;
}
* {
  box-sizing: border-box;
}
.content {
  width: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  margin-bottom: 24px;
}
.m-content {
  width: 360px;
  display: block;
}
.error-text {
  font-family: Inter;
  padding: 5px;
}
p {
  color: #464853;
  line-height: 1.4em;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  max-width: 500px;
}
h1 {
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 30px;
}
.btn {
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  border: none;
  text-decoration: none;
  &--medium {
    border-radius: 10px;
    font-size: 16px;
    padding: 0 21px;
    height: 46px;
    line-height: 46px;
  }
  &--primary {
    color: #fff;
    background-color: #0066ff;
    &:hover {
      background-color: #1a75ff;
    }
  }
  &--secondary {
    color: black;
    box-shadow: inset #b1b0b5 0 0 0 1px;
    background-color: transparent;
  }
  &:focus {
    outline: 2px solid #0066ff;
    outline-width: 2px;
    outline-offset: 2px;
  }
}
.input {
  appearance: none;
  transition: box-shadow 0.1s;
  border: none;
  width: 100%;
  color: black;
  background: #f5f5f5;
  outline: none;
  font: inherit;
  &-group {
    position: relative;
    margin-bottom: 15px;
  }
  &[type="password"] {
    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }
  &.i-medium {
    border-radius: 10px;
    height: 46px;
    padding: 0 14px;
    font-size: 15px;
  }
  &:focus {
    background: #eee;
  }
}
.passview {
  position: absolute;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  color: #121212;
  justify-content: center;
  right: 0;
  width: 46px;
  padding: 7px;
  top: 0;
  height: 100%;
}
.small-text {
  font-size: 12px;
  color: #7d7c83;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}
.link {
  text-decoration: none;
  font-weight: 500;
  &--black {
    color: #000;
    &:hover {
      color: #717171;
    }
  }
}
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
.name-server {
  background: #e9ecef;
  text-align: left;
  padding: 15px;
}
.name-server i {
  cursor: copy;
  margin-left: 5px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
